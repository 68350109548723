import { PackagePlus } from "lucide-react";
import NumUnitsDisplay from "./num-units-display";

export default function BackorderDisplay({
    backorderNumUnits,
    wholesaleUnitsPerCase,
    className
}: {
    backorderNumUnits: number;
    wholesaleUnitsPerCase: number;
    className?: string;
}) {
    return (
        <div
            className={`${className} flex flex-col items-baseline gap-1 text-sm text-backorder md:flex-row `}
        >
            <div className="flex flex-row items-center gap-1 whitespace-nowrap">
                <PackagePlus size={13} /> Expected Backorder:{" "}
            </div>
            <NumUnitsDisplay
                numUnits={backorderNumUnits}
                wholesaleUnitsPerCase={wholesaleUnitsPerCase}
            />
        </div>
    );
}
